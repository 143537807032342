
import HeadingSM from '@/components/wrappers/HeadingSM.vue'
import Saw from '@/components/parts/Saw.vue'
import HorizontalSVG from '@/components/svg/detail/HorizontalSVG.vue'
import HorizontalShortSVG from '@/components/svg/detail/HorizontalShortSVG.vue'
export default {
  components: {
    HeadingSM,
    Saw,
    HorizontalSVG,
    HorizontalShortSVG,
  },
  props: {
    section: {
      type: Object,
      default: () => ({}),
    },
    sectionIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tl: null,
    }
  },

  beforeMount() {
    this.$nuxt.$on('go', this.anim)
  },
  mounted() {
    this.tl = this.$gsap.timeline({
      scrollTrigger: {
        trigger: this.$el,
        start: 'top bottom',
      },
    })
    this.tl.scrollTrigger.disable()

    this.$refs.preheading && this.tl.from(this.$refs.preheading.$el, { opacity: 0, xPercent: -10, duration: 0.2 }, '<')
    this.$refs.investor && this.tl.from(this.$refs.investor, { opacity: 0, yPercent: 10, duration: 0.2, stagger: 0.1 }, '>')

    this.$emit('sectionMounted')
  },

  beforeDestroy() {
    this.$nuxt.$off('go', this.anim)
  },
  methods: {
    anim() {
      this.tl.scrollTrigger.enable()
    },
  },
}
